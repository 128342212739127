<template>
<div class="kt-portlet kt-portlet--head-noborder kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ markingPeriod.markingPeriodName }}
                <router-link
                    :class="`kt-font-lg kt-font ${color}`"
                    :to="{
                        name: 'StudentCourseOverview',
                        params: {
                            extCourseSectionId: course.extCourseSectionId,
                            studentEnrollmentId
                        }
                    }"
                >
                    <small v-if="reportCardGrade">
                        {{ reportCardGrade.scaled.displayName }}
                    </small>
                    <small v-else-if="progressAverage">
                        {{ progressAverage.scaled.displayName }}
                    </small>
                </router-link>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            {{ teacherName }}
        </div>
    </div>
    <div
        v-if="reportCardGrade || progressAverage"
        class="kt-portlet__body  kt-portlet__body--fit"
    >
        <div class="row">
            <div class="col-12">
                <div class="kt-widget20">
                    <div class="kt-widget20__content kt-portlet__space-x">
                        <div
                            v-if="reportCardGrade"
                            class="media"
                        >
                            <div
                                class="mr-3 align-self-center kt-badge kt-badge--xl kt-font-xl"
                                :class="[color, 'report-card-badge']"
                            >
                                {{ reportCardGrade.mark }}
                            </div>

                            <div class="media-body">
                                <router-link
                                    :to="{
                                        name: 'StudentCourseOverview',
                                        params: {
                                            extCourseSectionId: course.extCourseSectionId,
                                            studentEnrollmentId
                                        }
                                    }"
                                    class="kt-widget20__number"
                                    :class="`kt-font-lg kt-font ${color}`"
                                    style="height: 32px;"
                                >
                                    {{ course.name }}
                                </router-link>
                                <br>
                                <span class="kt-widget20__desc">
                                    Report Card Grade:
                                    <span :class="`ml-2 kt-font ${color}`">
                                        {{ reportCardGrade.mark }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div
                            v-else-if="progressAverage"
                            class="media"
                        >
                            <div
                                class="mr-3 align-self-center kt-badge kt-badge--xl kt-font-xl"
                                :class="[color, 'progress-average-badge' ]"
                            >
                                {{ progressAverage.scaled.mark }}
                            </div>

                            <div class="media-body">
                                <router-link
                                    :to="{
                                        name: 'StudentCourseOverview',
                                        params: {
                                            extCourseSectionId: course.extCourseSectionId,
                                            studentEnrollmentId
                                        }
                                    }"
                                    class="kt-widget20__number"
                                    :class="`kt-font-lg kt-font ${color}`"
                                    style="height: 32px;"
                                >
                                    {{ course.name }}
                                </router-link>
                                <br>
                                <span class="kt-widget20__desc ">
                                    Progress Average:
                                    <span
                                        v-if="showPercentages"
                                        :class="`ml-2 kt-font ${color}`"
                                    >
                                        {{ decimalToPercent(progressAverage.average) }}
                                    </span>
                                    <span
                                        v-else-if="showScaledScores"
                                        :class="`ml-2 kt-font ${color}`"
                                    >
                                        {{ progressAverage.scaled.mark }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="comments.length > 0"
            class="row px-4 pt-2"
        >
            <div class="col-12">
                <div class="kt-pagination kt-pagination--sm kt-pagination--info">
                    <div class="kt-pagination__toolbar">
                        <span class="pagination__desc">
                            Comment {{ selectedCommentIndex+1 }} of {{ comments.length }}
                        </span>
                    </div>
                    <ul class="kt-pagination__links">
                        <li
                            v-if="selectedCommentIndex > 0"
                            class="kt-pagination__link--next"
                        >
                            <a
                                href="#"
                                @click.stop.prevent="previousComment"
                            >
                                <i class="fa fa-angle-left kt-font-info" />
                            </a>
                        </li>
                        <li
                            class="kt-pagination__link--prev"
                        >
                            <a
                                href="#"
                                @click.stop.prevent="nextComment"
                            >
                                <i class="fa fa-angle-right kt-font-info" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="kt-section mb-0 pt-2">
                    <div class="kt-section__content">
                        {{ comment }}
                    </div>
                </div>
            </div>
        </div>
        <div class="kt-widget20__chart">
            <StudentBreakdownLineChart
                :category="reportCardGrade || progressAverage"
                :assignments="assignments"
                :clicked-assignment="openPanelForCourseWork"
                :height="130"
            />
        </div>
        <div
            :style="{background: gradient, paddingTop: '64px'}"
            class="kt-widget4 kt-widget4--sticky"
        >
            <div class="kt-widget4__items kt-widget4__items--bottom kt-portlet__space-x kt-margin-b-20">
                <div
                    v-for="cat in categories"
                    :key="`category_${cat.gradeTemplateCategoryId}`"
                    class="kt-widget4__item"
                >
                    <div class="kt-widget4__img kt-widget4__img--logo">
                        <span
                            class="btn btn-icon progress-average-badge mr-1"
                            :class="[cat.scaled.color, `btn-sm`]"
                        >
                            <span class="course-icon kt-font-lg align-items-center d-flex">
                                {{ cat.scaled.mark }}
                            </span>
                        </span>
                    </div>

                    <div class="kt-widget4__info">
                        <a
                            href="#"
                            class="kt-widget4__title"
                            @click.stop.prevent=""
                        >
                            {{ cat.categoryName }}
                        </a>
                        <span class="kt-widget4__sub">
                            Missing: {{ cat.totals.missing }} of {{ cat.totals.all }}
                        </span>
                    </div>
                    <span
                        v-if="!cat.prorated"
                        class="kt-widget4__ext"
                    >
                        <span
                            v-if="showPercentages"
                            :class="`kt-widget4__number kt-font ${cat.scaled.color}`"
                        >
                            {{ decimalToPercent(cat.percentEarned) }}
                        </span>
                        <span
                            v-else-if="showScaledScores"
                            :class="`kt-widget4__number kt-font ${cat.scaled.color}`"
                        >
                            {{ cat.scaled.mark }}
                        </span>
                    </span>
                    <span v-else>
                        <span :class="`kt-widget4__number kt-font Grey`">
                            N/A
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="kt-portlet__body"
    >
        <h4>
            <router-link
                :to="{
                    name: 'StudentCourseOverview',
                    params: {
                        extCourseSectionId: course.extCourseSectionId,
                        studentEnrollmentId
                    }
                }"
                class="kt-widget20__number"
                :class="`kt-font-lg kt-font ${color}`"
                style="height: 32px;"
            >
                {{ course.name }}
            </router-link>
        </h4>

        <div class="p-3 pb-5">
            <span class="text-muted">
                This course does not have enough data to supply an average
            </span>
        </div>
    </div>
</div>
</template>

<script>

import tinycolor from 'tinycolor2';
import { decimalToPercent } from '../store/mixins/utilMixins';
import { showPercentages, showScaledScores } from '../store/mixins/averagingMixins';
import StudentBreakdownLineChart from './StudentBreakdownLineChart.vue';

import panelMixins from '../store/mixins/panelMixins';
// @ts-ignore
import cssVars from '../css/colors.scss';

export default {
    name: 'StudentCourseOverall',
    components: {
        StudentBreakdownLineChart,
    },
    mixins: [panelMixins],
    props: {
        student: {
            type: Object,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
            selectedCommentIndex: 0,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        showPercentages() {
            return showPercentages(this.$store, this.course.gradeTemplate);
        },
        showScaledScores() {
            return showScaledScores(this.$store, this.course.gradeTemplate);
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        comments() {
            // later on add in progress reports
            const { reportCardGrade } = this;
            if (!reportCardGrade) return [];
            const comments = [...reportCardGrade.comments];
            if (reportCardGrade.narrativeComment) {
                comments.unshift(reportCardGrade.narrativeComment);
            }
            return comments;
        },
        comment() {
            const { comments, selectedCommentIndex } = this;
            return comments[selectedCommentIndex];
        },
        average() {
            const { schoolTermMarkingPeriodId } = this.$store.state.settings;
            const { course } = this;
            return course.studentMarkingPeriodAverages.find((a) => a.markingPeriod && a.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        progressAverage() {
            const { average } = this;
            if (!average) return null;
            const { progressAverage } = average;
            return progressAverage && progressAverage.scaled ? progressAverage : null;
        },
        reportCardGrade() {
            const { average } = this;
            if (!average) return null;
            const { reportCardGrade } = average;
            return reportCardGrade && reportCardGrade.scaled ? reportCardGrade : null;
        },
        markingPeriod() {
            const { average } = this;
            if (!average) return null;
            const { markingPeriod } = average;
            return markingPeriod;
        },
        teacherName() {
            const [first] = this.course.teachers;
            if (!first) return '';
            return `${first.lastName} ${first.firstName.substring(0, 1)}`;
        },
        categories() {
            const { progressAverage } = this;
            if (!progressAverage) return [];
            return progressAverage.categories.filter((c) => c.scaled);
        },
        color() {
            const { progressAverage, reportCardGrade } = this;
            if (reportCardGrade && reportCardGrade.scaled) return reportCardGrade.scaled.color;
            if (progressAverage && progressAverage.scaled) return progressAverage.scaled.color;
            return 'Grey';
        },
        gradient() {
            const { color } = this;
            const hexColor = cssVars[color];
            const c = tinycolor(hexColor);
            return `linear-gradient(0deg, rgba(0,0,0,0) 75%, ${c.setAlpha(0.3).toRgbString()} 100%)`;
        },
        assignments() {
            const { progressAverage, categories } = this;
            const assignments = [];
            if (!progressAverage) return assignments;
            categories.forEach((cat) => {
                cat.assignments.forEach((ass) => {
                    assignments.push(ass);
                });
            });
            return assignments;
        },
    },
    watch: {
        '$route.params.studentEnrollmentId': {
            handler() {
                this.key += 1;
            },
        },
    },
    methods: {
        decimalToPercent(decimal) {
            return decimalToPercent(decimal);
        },
        openPanelForCourseWork(courseWork) {
            this.$_panelMixins_openPanelForCourseWork(courseWork);
        },
        nextComment() {
            const { selectedCommentIndex, comments } = this;
            if (selectedCommentIndex < comments.length - 1) {
                this.selectedCommentIndex += 1;
            }
        },
        previousComment() {
            const { selectedCommentIndex } = this;
            if (selectedCommentIndex > 0) {
                this.selectedCommentIndex -= 1;
            }
        },
    },
};
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>
div.kt-widget20__chart:hover div.legend-container {
    display: none;
}
</style>

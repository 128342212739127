var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("StudentSubHeader", { attrs: { "show-marking-period-picker": true } }),
      _vm.student
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-lg-5 col-xl-4" },
                  [
                    _c("StudentHeaderPortlet"),
                    _vm.isPortfolioReady
                      ? _c("MarkingPeriodPerformance", {
                          attrs: {
                            "student-courses": _vm.studentCourses,
                            "marking-period-progress-averages":
                              _vm.markingPeriodProgressAverages,
                            "marking-period-report-card-averages":
                              _vm.markingPeriodReportCardAverages,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12 col-lg-7 col-xl-8" },
                  [
                    _c("StudentCourseListPortlet", {
                      staticClass: "kt-portlet--height-fluid",
                      attrs: {
                        student: _vm.student,
                        "student-courses": _vm.studentCourses,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.studentCourses, function (course, idx) {
                  return _c(
                    "div",
                    {
                      key: `course_${idx}_${course.extCourseSectionId}`,
                      staticClass: "col-xl-4 col-lg-6 col-md-6",
                    },
                    [
                      _c("StudentCourseOverall", {
                        attrs: { student: _vm.student, course: course },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.isPortfolioReady
                ? _c("StudentCourseSectionMeetings")
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [
      _c("div", { staticClass: "media pt-1" }, [
        _vm.course.nextMeeting && _vm.course.nextMeeting.day
          ? _c("div", { staticClass: "mr-3", staticStyle: { width: "80px" } }, [
              _c("span", { staticClass: "kt-font-lg" }, [
                _vm._v("Pd " + _vm._s(_vm.course.nextMeeting.period)),
              ]),
              _c("br"),
              _c("span", { staticClass: "text-muted" }, [
                _vm._v("Rm " + _vm._s(_vm.course.nextMeeting.room)),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "media-body" },
          [
            _c(
              "router-link",
              {
                staticClass: "course-title kt-font-bold kt-font-lg",
                attrs: {
                  to: {
                    name: "StudentCourseOverview",
                    params: {
                      extCourseSectionId: _vm.course.extCourseSectionId,
                      studentEnrollmentId: _vm.studentEnrollmentId,
                    },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
            ),
            _vm.course.teachers.length == 0
              ? _c("div", { staticClass: "text-muted" }, [
                  _vm._v(" No Teachers "),
                ])
              : _c(
                  "div",
                  { staticClass: "text-muted" },
                  _vm._l(_vm.course.teachers, function (teacher) {
                    return _c(
                      "router-link",
                      {
                        key: `${teacher.schoolStaffId}_teacher`,
                        staticClass: "mr-3 kt-link kt-font-dark",
                        attrs: {
                          to: {
                            name: "TeacherCourseInfo",
                            params: {
                              extCourseSectionId: _vm.course.extCourseSectionId,
                              schoolEmail: teacher.schoolEmail,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(teacher.lastName) + " ")]
                    )
                  }),
                  1
                ),
          ],
          1
        ),
      ]),
    ]),
    _c("td", [
      _c(
        "div",
        {
          class: `hstack row no-wrap hstack-marks-${_vm.course.studentMarkingPeriodAverages.length}mp`,
        },
        _vm._l(_vm.course.studentMarkingPeriodAverages, function (avg) {
          return _c("StudentMarkingPeriodMark", {
            key: `studentMarkingPeriodAverage_${avg.markingPeriod.schoolTermMarkingPeriodId}`,
            staticClass: "marking-period-grade mr-2 py-2",
            class: [
              avg.markingPeriod.schoolTermMarkingPeriodId ==
              _vm.schoolTermMarkingPeriodId
                ? "active-marking-period"
                : "",
            ],
            attrs: {
              course: _vm.course,
              "student-marking-period-average": avg,
            },
          })
        }),
        1
      ),
    ]),
    _c("td", [
      _c("div", { staticClass: "kt-widget29 mt-0 pt-1 ml-3" }, [
        _c("div", { staticClass: "kt-widget29__content p-0" }, [
          _c("div", { staticClass: "kt-widget29__item" }, [
            _c("div", { staticClass: "kt-widget29__info text-center" }, [
              _c(
                "span",
                {
                  class: [
                    "kt-widget29__stats",
                    _vm.course.studentCourseAttendance.percent.present >= 95
                      ? "kt-font-primary"
                      : "",
                    _vm.course.studentCourseAttendance.percent.present >= 90
                      ? "kt-font-success"
                      : "kt-font-danger",
                  ],
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.course.studentCourseAttendance.percent.present
                      ) +
                      "% "
                  ),
                ]
              ),
              _c("span", { staticClass: "kt-widget29__subtitle" }, [
                _vm._v("YTD"),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("td", [
      _c("div", { staticClass: "kt-widget29 mt-0 pt-1" }, [
        _c("div", { staticClass: "kt-widget29__content p-0" }, [
          _c("div", { staticClass: "kt-widget29__item" }, [
            _c("div", { staticClass: "kt-widget29__info text-center" }, [
              _c("span", { staticClass: "kt-widget29__stats kt-font-danger" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.course.studentCourseAttendance.total.absent) +
                    " "
                ),
              ]),
              _c("span", { staticClass: "kt-widget29__subtitle" }, [
                _vm._v("ABS"),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("td", [
      _c(
        "div",
        { staticClass: "hstack hstack-attendance row no-wrap ml-3" },
        _vm._l(_vm.thisWeeksAttendance, function (att, idx) {
          return _c(
            "span",
            {
              key: `attendance_${idx}_${att.attendanceDate}`,
              staticClass: "mr-2 pt-2",
            },
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  class: `kt-badge kt-badge--lg kt-badge--bold kt-font-lg kt-badge--unified-${att.className}`,
                  attrs: { href: "#", title: att.title },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        att.record
                          ? att.record.attendanceState.substring(0, 1)
                          : "-"
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
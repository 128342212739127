<script>
import { Doughnut } from 'vue-chartjs';

export default {
    name: 'MarkingPeriodPerformanceChart',
    components: {
        Doughnut,
    },
    extends: Doughnut,
    props: {
        items: {
            type: Array,
            required: true,
        },
        clickedSlice: {
            type: Function,
            required: true,
        },
    },
    computed: {
        chartData() {
            const { labels, colors, values } = this;
            return {
                labels,
                datasets: [{
                    backgroundColor: colors,
                    data: values,
                }],
            };
        },
        labels() {
            return this.items.map((item) => item.label);
        },
        colors() {
            return this.items.map((item) => item.color);
        },
        values() {
            return this.items.map((item) => item.value);
        },
        chartOptions() {
            return {
                cutoutPercentage: 75,
                responsive: true,
                maintainAspectRatio: true,
                onClick: this.handleClick,
                legend: {
                    display: false,
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                tooltips: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    bodySpacing: 5,
                    yPadding: 10,
                    xPadding: 10,
                },
            };
        },
    },
    mounted() {
        const { renderChart, chartData, chartOptions } = this;
        this.$nextTick(() => {
            // @ts-ignore
            renderChart(chartData, chartOptions);
        });
    },
    methods: {
        handleClick(point, event) {
            const [e] = event;
            if (!e) return;
            const index = e._index;
            this.clickedSlice(this.items[index].label);
        },
    },
};
</script>

<template>
<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ markingPeriod.markingPeriodName }}
            </h3>
        </div>
    </div>
    <div
        id="mp-chart-container"
        :key="`perf_${key}`"
        class="kt-portlet__body pt-0"
    >
        <div
            v-if="markingPeriodAverage && markingPeriodAverage.scaled"
            class="kt-widget16"
        >
            <div
                v-if="ready"
                class="kt-widget16__stats"
            >
                <div class="kt-widget16__visual w-100">
                    <div class="kt-widget16__chart">
                        <div
                            :class="[
                                `kt-widget16__avg font ${markingPeriodAverage.scaled.color}`,
                                showScaledScores && showPercentages ? '' :`kt-widget16__avg-single`,
                            ]"
                        >
                            <template v-if="showScaledScores && showPercentages">
                                {{ markingPeriodAverage.scaled.mark }}
                                <div class="text-muted">
                                    {{ markingPeriodAverage.average }}%
                                </div>
                            </template>
                            <template v-else-if="showScaledScores">
                                {{ markingPeriodAverage.scaled.mark }}
                            </template>
                            <template v-else-if="showPercentages">
                                {{ markingPeriodAverage.average }}%
                            </template>
                        </div>

                        <MarkingPeriodPerformanceChart
                            :height="200"
                            :clicked-slice="chartClicked"
                            :width="width"
                            :items="chartItems"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="markingPeriodReportCardAverage"
            class="kt-font-lg kt-font-bold text-center w-100 pt-3"
        >
            Report Card Average
        </div>
        <div
            v-else-if="markingPeriodProgressAverage"
            class="kt-font-lg kt-font-bold text-center w-100 pt-3"
        >
            Progress Average
        </div>
        <div class="kt-section mb-0 pt-2 pb-3">
            <div class="kt-section__desc text-center">
                {{ markingPeriodRange }}
            </div>
        </div>

        <div
            v-for="avg in averages"
            :key="`avg_course_${avg.courseSectionId}`"
            class="kt-widget24 p-1 m-1"
        >
            <template v-if="avg.reportCardGrade && avg.reportCardGrade.scaled">
                <div class="progress progress--sm mt-2">
                    <div
                        :class="`progress-bar ${avg.reportCardGrade.scaled.color}`"
                        role="progressbar"
                        :style="{width: `${avg.reportCardGrade.numericEquivalent}%`}"
                        :aria-valuenow="`${avg.reportCardGrade.numericEquivalent}`"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                </div>
                <div class="kt-widget24__action">
                    <span
                        :class="`font ${ avg.reportCardGrade.scaled.color }`"
                        class="kt-widget24__change"
                    >
                        {{ avg.courseSectionTitle }}
                    </span>

                    <span
                        v-if="showPercentages"
                        class="kt-widget24__number"
                    >
                        {{ avg.reportCardGrade.numericEquivalent }}%
                    </span>
                    <span
                        v-else-if="showScaledScores"
                        class="kt-widget24__number"
                    >
                        {{ avg.reportCardGrade.mark }}
                    </span>
                </div>
            </template>
            <template v-else-if="avg.progressAverage && avg.progressAverage.scaled">
                <div class="progress progress--sm mt-2">
                    <div
                        :class="`progress-bar ${avg.progressAverage.scaled.color}`"
                        role="progressbar"
                        :style="{width: `${decimalToPercent(avg.progressAverage.average)}`}"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                </div>
                <div class="kt-widget24__action">
                    <span
                        :class="`font ${ avg.progressAverage.scaled.color }`"
                        class="kt-widget24__change"
                    >
                        {{ avg.courseSectionTitle }}
                    </span>
                    <span
                        v-if="showPercentages"
                        class="kt-widget24__number"
                    >
                        {{ decimalToPercent(avg.progressAverage.average) }}
                    </span>
                    <span
                        v-else-if="showScaledScores"
                        class="kt-widget24__number"
                    >
                        {{ avg.progressAverage.scaled.mark }}
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="progress progress--sm mt-2">
                    <div
                        :class="`progress-bar Grey`"
                        role="progressbar"
                        :style="{width: `0%`}"
                        :aria-valuenow="`0`"
                        aria-valuemin="0"
                        aria-valuemax="0"
                    />
                </div>
                <div class="kt-widget24__action">
                    <span
                        :class="`font Grey`"
                        class="kt-widget24__change"
                    >
                        {{ avg.courseSectionTitle }}
                    </span>

                    <span class="kt-widget24__number">
                        -
                    </span>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { decimalToPercent } from '../store/mixins/utilMixins';
import { showPercentages, showScaledScores } from '../store/mixins/averagingMixins';
import MarkingPeriodPerformanceChart from './MarkingPeriodPerformanceChart.vue';

export default Vue.extend({
    name: 'MarkingPeriodPerformance',
    components: {
        MarkingPeriodPerformanceChart,
    },
    props: {
        studentCourses: {
            type: Array,
            required: true,
        },
        markingPeriodProgressAverages: {
            type: Array,
            required: true,
        },
        markingPeriodReportCardAverages: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
            ready: false,
            filter: null,
            width: null,
            height: null,
        };
    },
    computed: {
        windowSize() {
            return `${this.$store.state.window.width}_${this.$store.state.window.height}`;
        },
        user() {
            return this.$store.state.user;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        showPercentages() {
            return showPercentages(this.$store, this.gradeTemplate);
        },
        showScaledScores() {
            return showScaledScores(this.$store, this.gradeTemplate);
        },
        gradeTemplate() {
            // since its all courses, use school default template
            const { gradeTemplates } = this.$store.state.database;
            const { schoolId } = this.$store.state.user.school;
            return gradeTemplates.find((t) => t.schoolDefaultSchoolId == schoolId) || null;
        },
        markingPeriods() {
            const { markingPeriods } = this.$store.state.database;
            if (!markingPeriods || !markingPeriods.length) return [];
            return markingPeriods.sort((a, b) => a.markingPeriodStart.localeCompare(b.markingPeriodStart));
        },
        markingPeriod() {
            const { markingPeriods, schoolTermMarkingPeriodId } = this;
            if (!markingPeriods || !markingPeriods.length) return null;
            return markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        markingPeriodRange() {
            const shortDate = 'ddd, MMM Do';
            const { markingPeriod } = this;
            if (!markingPeriod) return null;
            const markingPeriodStart = moment(markingPeriod.markingPeriodStart);
            const markingPeriodEnd = moment(markingPeriod.markingPeriodEnd);
            return `From ${markingPeriodStart.format(shortDate)} through ${markingPeriodEnd.format(shortDate)}`;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        averages() {
            const { studentCourses, schoolTermMarkingPeriodId, filter } = this;
            const useReportCardAverages = Boolean(this.markingPeriodReportCardAverage);

            return studentCourses.map((course) => {
                const { courseSectionTitle, courseSectionId } = course;
                const markingPeriodAverage = course.studentMarkingPeriodAverages.find((m) => m.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                if (markingPeriodAverage) {
                    const { reportCardGrade, progressAverage } = markingPeriodAverage;
                    if (!reportCardGrade && !progressAverage) return null;
                    let displayName = '';
                    if (useReportCardAverages) {
                        if (reportCardGrade && reportCardGrade.scaled) {
                            displayName = reportCardGrade.scaled.displayName;
                        }
                    } else if (progressAverage && progressAverage.scaled) {
                        displayName = progressAverage.scaled.displayName;
                    }

                    if (filter && displayName !== filter) return null;

                    return {
                        schoolTermMarkingPeriodId,
                        courseSectionId,
                        courseSectionTitle,
                        reportCardGrade,
                        progressAverage: progressAverage && progressAverage.scaled ? progressAverage : null,
                    };
                }
                return {
                    schoolTermMarkingPeriodId,
                    courseSectionId,
                    courseSectionTitle,
                    reportCardGrade: null,
                    progressAverage: null,
                };
            }).filter((avg) => {
                if (!avg) return false;
                if (avg.reportCardGrade === null && avg.progressAverage === null) return false;
                return true;
            });
        },
        markingPeriodReportCardAverage() {
            const { schoolTermMarkingPeriodId, markingPeriodReportCardAverages } = this;
            return markingPeriodReportCardAverages.find((m) => m.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        markingPeriodProgressAverage() {
            const { schoolTermMarkingPeriodId, markingPeriodProgressAverages } = this;
            return markingPeriodProgressAverages.find((m) => m.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        markingPeriodAverage() { // either progress or report card
            const { markingPeriodProgressAverage, markingPeriodReportCardAverage } = this;
            return markingPeriodReportCardAverage || markingPeriodProgressAverage || null;
        },
        chartItems() {
            return this.markingPeriodAverage.totalsByGradeScales;
        },
    },
    watch: {
        windowSize() {
            this.ready = false;
            this.render();
        },
        schoolTermMarkingPeriodId() {
            this.ready = false;
            this.filter = null;
            this.render();
        },
        forceStudentView() {
            this.ready = false;
            this.render();
        },
    },
    mounted() {
        this.render();
    },
    methods: {
        decimalToPercent(decimal) {
            return decimalToPercent(decimal);
        },
        chartClicked(displayName) {
            if (this.filter == displayName) {
                this.filter = null;
            } else {
                this.filter = displayName;
            }
        },
        render() {
            setTimeout(() => {
                const container = document.getElementById('mp-chart-container');
                if (container) {
                    this.width = container.clientWidth * 0.35;
                    // this.height = container.clientHeight * 0.8;
                    this.ready = true;
                    this.key += 1;
                }
            }, 500);
        },
    },
});

</script>

<style scoped>
.kt-widget16__avg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -20px;
    font-size: 2.2rem;
    font-weight: 500;
}
.kt-widget16__avg-single{
    top: 0;
}

.kt-widget16__avg .text-muted {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;
    font-size: 1.1rem;
    font-weight: normal;
}

.kt-widget16 {
    height: 200px;
}
</style>

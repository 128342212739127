var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" " + _vm._s(_vm.markingPeriod.markingPeriodName) + " "),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        key: `perf_${_vm.key}`,
        staticClass: "kt-portlet__body pt-0",
        attrs: { id: "mp-chart-container" },
      },
      [
        _vm.markingPeriodAverage && _vm.markingPeriodAverage.scaled
          ? _c("div", { staticClass: "kt-widget16" }, [
              _vm.ready
                ? _c("div", { staticClass: "kt-widget16__stats" }, [
                    _c("div", { staticClass: "kt-widget16__visual w-100" }, [
                      _c(
                        "div",
                        { staticClass: "kt-widget16__chart" },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                `kt-widget16__avg font ${_vm.markingPeriodAverage.scaled.color}`,
                                _vm.showScaledScores && _vm.showPercentages
                                  ? ""
                                  : `kt-widget16__avg-single`,
                              ],
                            },
                            [
                              _vm.showScaledScores && _vm.showPercentages
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.markingPeriodAverage.scaled.mark
                                        ) +
                                        " "
                                    ),
                                    _c("div", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.markingPeriodAverage.average
                                          ) +
                                          "% "
                                      ),
                                    ]),
                                  ]
                                : _vm.showScaledScores
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.markingPeriodAverage.scaled.mark
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm.showPercentages
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.markingPeriodAverage.average
                                        ) +
                                        "% "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("MarkingPeriodPerformanceChart", {
                            attrs: {
                              height: 200,
                              "clicked-slice": _vm.chartClicked,
                              width: _vm.width,
                              items: _vm.chartItems,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.markingPeriodReportCardAverage
          ? _c(
              "div",
              { staticClass: "kt-font-lg kt-font-bold text-center w-100 pt-3" },
              [_vm._v(" Report Card Average ")]
            )
          : _vm.markingPeriodProgressAverage
          ? _c(
              "div",
              { staticClass: "kt-font-lg kt-font-bold text-center w-100 pt-3" },
              [_vm._v(" Progress Average ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "kt-section mb-0 pt-2 pb-3" }, [
          _c("div", { staticClass: "kt-section__desc text-center" }, [
            _vm._v(" " + _vm._s(_vm.markingPeriodRange) + " "),
          ]),
        ]),
        _vm._l(_vm.averages, function (avg) {
          return _c(
            "div",
            {
              key: `avg_course_${avg.courseSectionId}`,
              staticClass: "kt-widget24 p-1 m-1",
            },
            [
              avg.reportCardGrade && avg.reportCardGrade.scaled
                ? [
                    _c("div", { staticClass: "progress progress--sm mt-2" }, [
                      _c("div", {
                        class: `progress-bar ${avg.reportCardGrade.scaled.color}`,
                        style: {
                          width: `${avg.reportCardGrade.numericEquivalent}%`,
                        },
                        attrs: {
                          role: "progressbar",
                          "aria-valuenow": `${avg.reportCardGrade.numericEquivalent}`,
                          "aria-valuemin": "0",
                          "aria-valuemax": "100",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "kt-widget24__action" }, [
                      _c(
                        "span",
                        {
                          staticClass: "kt-widget24__change",
                          class: `font ${avg.reportCardGrade.scaled.color}`,
                        },
                        [_vm._v(" " + _vm._s(avg.courseSectionTitle) + " ")]
                      ),
                      _vm.showPercentages
                        ? _c("span", { staticClass: "kt-widget24__number" }, [
                            _vm._v(
                              " " +
                                _vm._s(avg.reportCardGrade.numericEquivalent) +
                                "% "
                            ),
                          ])
                        : _vm.showScaledScores
                        ? _c("span", { staticClass: "kt-widget24__number" }, [
                            _vm._v(
                              " " + _vm._s(avg.reportCardGrade.mark) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                : avg.progressAverage && avg.progressAverage.scaled
                ? [
                    _c("div", { staticClass: "progress progress--sm mt-2" }, [
                      _c("div", {
                        class: `progress-bar ${avg.progressAverage.scaled.color}`,
                        style: {
                          width: `${_vm.decimalToPercent(
                            avg.progressAverage.average
                          )}`,
                        },
                        attrs: {
                          role: "progressbar",
                          "aria-valuemin": "0",
                          "aria-valuemax": "100",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "kt-widget24__action" }, [
                      _c(
                        "span",
                        {
                          staticClass: "kt-widget24__change",
                          class: `font ${avg.progressAverage.scaled.color}`,
                        },
                        [_vm._v(" " + _vm._s(avg.courseSectionTitle) + " ")]
                      ),
                      _vm.showPercentages
                        ? _c("span", { staticClass: "kt-widget24__number" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.decimalToPercent(
                                    avg.progressAverage.average
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm.showScaledScores
                        ? _c("span", { staticClass: "kt-widget24__number" }, [
                            _vm._v(
                              " " +
                                _vm._s(avg.progressAverage.scaled.mark) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "progress progress--sm mt-2" }, [
                      _c("div", {
                        class: `progress-bar Grey`,
                        style: { width: `0%` },
                        attrs: {
                          role: "progressbar",
                          "aria-valuenow": `0`,
                          "aria-valuemin": "0",
                          "aria-valuemax": "0",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "kt-widget24__action" }, [
                      _c(
                        "span",
                        {
                          staticClass: "kt-widget24__change",
                          class: `font Grey`,
                        },
                        [_vm._v(" " + _vm._s(avg.courseSectionTitle) + " ")]
                      ),
                      _c("span", { staticClass: "kt-widget24__number" }, [
                        _vm._v(" - "),
                      ]),
                    ]),
                  ],
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div :key="`key_${title}_${key}`">
    <StudentSubHeader :show-marking-period-picker="true" />

    <div v-if="student" class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-md-12 col-lg-5 col-xl-4">
                <StudentHeaderPortlet />
                <MarkingPeriodPerformance
                    v-if="isPortfolioReady"
                    :student-courses="studentCourses"
                    :marking-period-progress-averages="markingPeriodProgressAverages"
                    :marking-period-report-card-averages="markingPeriodReportCardAverages"
                />
            </div>
            <div class="col-md-12 col-lg-7 col-xl-8">
                <StudentCourseListPortlet
                    :student="student"
                    class="kt-portlet--height-fluid"
                    :student-courses="studentCourses"
                />
            </div>
        </div>

        <div class="row">
            <div
                v-for="(course, idx) in studentCourses"
                :key="`course_${idx}_${course.extCourseSectionId}`"
                class="col-xl-4 col-lg-6 col-md-6"
            >
                <StudentCourseOverall
                    :student="student"
                    :course="course"
                />
            </div>
        </div>

        <StudentCourseSectionMeetings v-if="isPortfolioReady" />
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import portfolioMixins from '../store/mixins/portfolioMixins';
import studentMixins from '../store/mixins/studentMixins';
import userMixins from '../store/mixins/userMixins';
import courseMixins from '../store/mixins/courseMixins';
import averagingMixins from '../store/mixins/averagingMixins';
import StudentCourseListPortlet from '../components/StudentCourseListPortlet.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import MarkingPeriodPerformance from '../components/MarkingPeriodPerformance.vue';
import StudentSubHeader from './StudentSubHeader.vue';
import StudentCourseOverall from '../components/StudentCourseOverall.vue';
import StudentCourseSectionMeetings from '../components/StudentCourseSectionMeetings.vue';
// @ts-ignore
import cssVars from '../css/colors.scss';

export default Vue.extend({
    name: 'StudentInfo',
    components: {
        StudentCourseListPortlet,
        StudentHeaderPortlet,
        MarkingPeriodPerformance,
        StudentSubHeader,
        StudentCourseOverall,
        StudentCourseSectionMeetings,
    },
    mixins: [
        portfolioMixins,
        studentMixins,
        userMixins,
        courseMixins,
        averagingMixins,
    ],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        deviceType() {
            return this.$store.state.deviceType;
        },
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean((this.$store.state.database.cache).find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        markingPeriods() {
            const { markingPeriods } = this.$store.state.database;
            if (!markingPeriods || !markingPeriods.length) return [];
            return markingPeriods.slice().sort((a, b) => a.markingPeriodStart.localeCompare(b.markingPeriodStart));
        },
        gradeTemplate() {
            // since its all courses, use school default template
            const { gradeTemplates } = this.$store.state.database;
            const { schoolId } = this.$store.state.user.school;
            return gradeTemplates.find((t) => t.schoolDefaultSchoolId == schoolId) || null;
        },
        markingPeriodReportCardAverages() {
            const { markingPeriods, gradeTemplate } = this;
            const { floorAverages, scale } = gradeTemplate;

            return markingPeriods.map((mp) => {
                const { schoolTermMarkingPeriodId } = mp;
                let totalsByGradeScales = [];
                const studentCourses = [];

                // only include courses with grades when averaging
                this.studentCourses.forEach((c) => {
                    const averages = c.studentMarkingPeriodAverages;
                    const studentMarkingPeriodAverage = averages.find((m) => m.reportCardGrade && m.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                    const { gradeTemplateId } = c.gradeTemplate;
                    if (studentMarkingPeriodAverage) {
                        studentCourses.push(c);
                        if (studentMarkingPeriodAverage.reportCardGrade && studentMarkingPeriodAverage.reportCardGrade.scaled) {
                            const { scaled } = studentMarkingPeriodAverage.reportCardGrade;
                            const exists = totalsByGradeScales
                                .find((x) => x.label == scaled.displayName && x.gradeTemplateId == gradeTemplateId);

                            if (exists) {
                                totalsByGradeScales = totalsByGradeScales.map((g) => {
                                    if (g.label == scaled.displayName && g.gradeTemplateId == gradeTemplateId) {
                                        const gs = { ...g };
                                        gs.value += 1;
                                        return gs;
                                    }
                                    return g;
                                });
                            } else {
                                totalsByGradeScales.push({
                                    gradeTemplateId,
                                    label: scaled.displayName,
                                    color: cssVars[scaled.color],
                                    value: 1,
                                });
                            }
                        }
                    }
                });

                if (studentCourses.length == 0) return null;
                let totalCourses = 0;

                // calculate pointsEarned for overall average
                const pointsEarned = studentCourses.reduce((acc, course) => {
                    const mpAverage = course.studentMarkingPeriodAverages
                        .find((x) => x.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                    if (mpAverage && mpAverage.reportCardGrade && mpAverage.reportCardGrade.numericEquivalent) {
                        totalCourses += 1;
                        return acc + mpAverage.reportCardGrade.numericEquivalent;
                    }
                    return acc;
                }, 0);

                if (totalCourses == 0) return null;
                // format and scale overall average
                const pointsPossible = totalCourses * 100;
                let percent = pointsPossible == 0 ? 0 : round(pointsEarned / pointsPossible, 4);
                if (floorAverages && (percent * 100) > floorAverages) {
                    percent = (floorAverages / 100);
                }
                const rounded = round(percent * 100, 0);
                const scaled = pointsPossible == 0 ? null : scale.grades.find((s) => rounded >= s.minGrade && rounded <= s.maxGrade) || null;
                const output = {
                    schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                    average: rounded,
                    percent,
                    pointsEarned,
                    pointsPossible,
                    scaled,
                    totalsByGradeScales,
                };

                return output;
            }).filter((x) => x);
        },
        markingPeriodProgressAverages() {
            const { markingPeriods, gradeTemplate } = this;
            const { floorAverages, scale } = gradeTemplate;

            return markingPeriods.map((mp) => {
                const { schoolTermMarkingPeriodId } = mp;
                let totalsByGradeScales = [];
                const studentCourses = [];

                this.studentCourses.forEach((c) => {
                    const averages = c.studentMarkingPeriodAverages;
                    const studentMarkingPeriodAverage = averages.find((m) => m.progressAverage && m.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                    if (studentMarkingPeriodAverage) {
                        const { gradeTemplateId } = c.gradeTemplate;
                        studentCourses.push(c);

                        if (studentMarkingPeriodAverage.progressAverage && studentMarkingPeriodAverage.progressAverage.scaled) {
                            const { scaled } = studentMarkingPeriodAverage.progressAverage;
                            const exists = totalsByGradeScales
                                .find((x) => x.label == scaled.displayName && x.gradeTemplateId == gradeTemplateId);

                            if (exists) {
                                totalsByGradeScales = totalsByGradeScales.map((g) => {
                                    if (g.label == scaled.displayName && g.gradeTemplateId == gradeTemplateId) {
                                        const gs = { ...g };
                                        gs.value += 1;
                                        return gs;
                                    }
                                    return g;
                                });
                            } else {
                                totalsByGradeScales.push({
                                    gradeTemplateId,
                                    label: scaled.displayName,
                                    color: cssVars[scaled.color],
                                    value: 1,
                                });
                            }
                        }
                    }
                });

                if (studentCourses.length == 0) return null;
                let totalCourses = 0;

                const pointsEarned = studentCourses.reduce((acc, course) => {
                    const mpAverage = course.studentMarkingPeriodAverages
                        .find((x) => x.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                    if (mpAverage && mpAverage.progressAverage && mpAverage.progressAverage.scaled) {
                        totalCourses += 1;
                        return acc + mpAverage.progressAverage.average;
                    }
                    return acc;
                }, 0);

                if (totalCourses == 0) return null;
                const pointsPossible = totalCourses;
                let percent = pointsPossible == 0 ? 0 : round(pointsEarned / pointsPossible, 4);
                if (floorAverages && (percent * 100) > floorAverages) {
                    percent = (floorAverages / 100);
                }

                const rounded = round(percent * 100, 0);
                const scaled = pointsPossible == 0 ? null : scale.grades.find((s) => rounded >= s.minGrade && rounded <= s.maxGrade) || null;

                const output = {
                    schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                    average: rounded,
                    percent,
                    pointsEarned,
                    pointsPossible,
                    scaled,
                    totalsByGradeScales,
                };

                return output;
            }).filter((x) => x);
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        studentCourses() {
            const { studentEnrollmentId, isPortfolioReady } = this;
            if (!isPortfolioReady) return [];
            const studentCourses = this.$_courseMixins_getCoursesForStudentEnrollmentId(studentEnrollmentId)
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
            return studentCourses;
        },
    },
    watch: {
        studentEnrollmentId() {
            this.key += 1;
            this.calculateAverages();
        },
        isPortfolioReady() {
            this.calculateAverages();
        },
        forceStudentView() {
            this.key += 1;
        },
    },
    mounted() {
        this.calculateAverages();
    },
    methods: {
        calculateAverages() {
            const { studentEnrollmentId } = this;
            if (!studentEnrollmentId) return;
            if (!this.isPortfolioReady) return;
            this.$_averagingMixins_calculateAverageForStudent(studentEnrollmentId);
        },
    },
});

function round(value, decimals) {
    // @ts-ignore
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

</script>

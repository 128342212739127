<template>
<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon pr-0">
                <SVGIcon
                    hex-color="#0f88ef"
                    class="mr-3"
                    name="calendar"
                />
            </span>
            <h3 class="kt-portlet__head-title">
                <span>
                    Student Grid Schedule
                </span>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <button
                class="btn"
                :class="{'btn-clean btn-clean-primary': hovering != 'all',
                         'btn-label-primary': hovering == 'all'
                }"
                @click.stop.prevent="showAllAttendance()"
            >
                {{ hovering == 'all' ? 'Hide Attendance' : 'View Attendance' }}
            </button>
            <span class="ml-2">
                <b-form-datepicker
                    v-model="selectedDate"
                    :no-key-nav="true"
                    button-only
                    right
                    button-variant="primary"
                    autocomplete="off"
                    locale="en-US"
                    @context="onContextEnd"
                />
            </span>
        </div>
    </div>

    <div class="kt-portlet__body">
        <div class="kt-section__content pt-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Period</th>
                            <th
                                v-for="(day, idx) in days"
                                :key="`${day}_${idx}`"
                            >
                                <div class="text-center">
                                    {{ day }}
                                    <div>
                                        {{ dateForWeekday(day) }}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(row, rowIndex) in tableData"
                            :key="`row_${row.period}_${rowIndex}`"
                        >
                            <td>
                                <div class="text-center mt-2">
                                    <span
                                        class="btn btn-md btn-icon"
                                        style="cursor: default !important;"
                                        :class="[
                                            row.meetings.find((m) => m.course) ? row.color : ''
                                        ]"
                                    >
                                        <span
                                            v-if="row.meetings.find((m) => m.course)"
                                            :style="{color: row.hexColor}"
                                        >
                                            {{ row.period }}
                                        </span>
                                        <span v-else>
                                            {{ row.period }}
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td
                                v-for="(meeting, meetingIdx) in row.meetings"
                                :key="`row_${row.period}_${rowIndex}_${meeting.day}_${meetingIdx}`"
                            >
                                <router-link
                                    v-if="meeting.course"
                                    :to="{
                                        name: 'StudentCourseOverview',
                                        params: {
                                            studentEnrollmentId: student.studentEnrollmentId,
                                            extCourseSectionId: meeting.course.extCourseSectionId,
                                        },
                                    }"
                                    class="btn btn-clean position-relative"
                                    :class="['btn-clean-' + row.color,
                                             {'hovered': hovering.includes(`${meeting.course.courseSectionId}_${meeting.day}`)},
                                             {'hovered': hovering == 'all'},
                                    ]"
                                    @mouseover.native="showAttendance(meeting)"
                                >
                                    <div class="course-info">
                                        <span>
                                            {{ meeting.course.extCourseSectionId }}
                                            <br>
                                            {{ meeting.course.name }}
                                            <br>
                                            {{ meeting.room }}
                                        </span>
                                    </div>
                                    <div class="course-attendance">
                                        <span
                                            v-if="meeting.attendance"
                                            :class="[
                                                'btn btn-icon btn-sm btn-upper btn-bold att-text',
                                                meeting.attendance.attendanceColor,
                                            ]"
                                        >
                                            {{ `${meeting.attendance.attendanceState || ''}`.toUpperCase().substring(0, 1) }}
                                        </span>
                                        <span
                                            v-else-if="!meeting.attendance"
                                            class="btn btn-icon btn-sm btn-upper btn-bold att-text Grey"
                                        >  -
                                        </span>
                                    </div>
                                </router-link>
                                <span
                                    v-else
                                    class="text-muted"
                                > - </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import moment from 'moment';
import Vue from 'vue';
// @ts-ignore
import cssVars from '../css/colors.scss';

import courseMixins from '../store/mixins/courseMixins';
import studentMixins, { getStudents, StudentFilter } from '../store/mixins/studentMixins';

export default Vue.extend({
    name: 'StudentCourseSectionMeetings',
    mixins: [
        courseMixins,
        studentMixins,
    ],
    data() {
        return {
            selectedDate: '',
            hovering: [],
        };
    },
    computed: {
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        studentCourses() {
            if (!this.student) return [];
            const { studentEnrollmentId } = this.student;
            const courses = this.$_courseMixins_getCoursesForStudentEnrollmentId(studentEnrollmentId);
            return courses.sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
        },
        courseMeetings() {
            let output = [];
            this.studentCourses.forEach((c) => {
                if (c.courseMeetings) {
                    const rows = c.courseMeetings.map((m) => {
                        const meeting = { ...m }; // new pointer
                        meeting.course = { ...c }; // new pointer
                        return meeting;
                    });
                    output = [
                        ...rows,
                        ...output,
                    ];
                }
            });
            return output;
        },
        days() {
            const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
            // this adds in sat & sun, if the course meets
            const { courseMeetings } = this;
            courseMeetings.forEach((m) => {
                const shortName = m.day.substring(0, 3);
                if (!dayNames.includes(shortName)) {
                    dayNames.push(shortName);
                }
            });
            return dayNames;
        },
        periods() {
            const periods = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
            // const periods = [];
            const { courseMeetings } = this;
            courseMeetings.forEach((m) => {
                if (!periods.includes(m.period)) {
                    periods.push(m.period);
                }
            });
            return periods;
        },
        tableData() {
            const {
                periods, days, courseMeetings, hasAttendance,
            } = this;
            const rows = [];
            periods.forEach((period) => {
                const digit = period ? (period || '0').slice(-1) : '0'; // last char
                const color = period ? `color${digit}` : 'color0';
                const hexColor = cssVars[color];

                const row = {
                    color,
                    hexColor,
                    period,
                    meetings: [],
                };
                days.forEach((day) => {
                    const meeting = courseMeetings.find((m) => m.period == period && m.day.substring(0, 3) == day);
                    const attendance = hasAttendance({ ...meeting, day });
                    row.meetings.push({
                        day,
                        course: meeting ? meeting.course : null,
                        room: meeting ? meeting.room : null,
                        attendance,
                    });
                });
                rows.push(row);
            });
            return rows;
        },
        studentAttendance() {
            const colors = {
                A: 'Red',
                P: 'Green',
                L: 'Yellow',
                E: 'Blue',
            };
            const { studentCourses } = this;
            if (!studentCourses || !studentCourses.length) return [];

            const { database } = this.$store.state;
            const { courseSectionAttendance } = database;
            const { studentEnrollmentId } = this.student;
            return courseSectionAttendance.filter((a) => {
                const { courseSectionId, courseSectionStudentId, deleted } = a;
                if (deleted) return false;
                const course = studentCourses.find((c) => c.courseSectionId == courseSectionId);
                if (!course) return false;

                const [student] = getStudents(database, new StudentFilter({ studentEnrollmentId, courseSectionId }));
                if (!student) return false;

                if (courseSectionStudentId == student.courseSectionStudentId && courseSectionId == course.courseSectionId) return true;
                return false;
            }).map((a) => {
                const attendance = a;
                attendance.attendanceColor = colors[`${attendance.attendanceState || ''}`.substring(0, 1).toUpperCase()] || '';
                return attendance;
            });
        },
    },
    mounted() {
        this.selectedDate = moment().format('YYYY-MM-DD');
    },
    methods: {
        onContextEnd(ctx) {
            const { selectedYMD } = ctx;
            this.selectedDate = selectedYMD;
        },
        hasAttendance(meeting) {
            const { course, day } = meeting;
            if (!course || !day) return null;
            const { studentAttendance, days, selectedDate } = this;
            const { courseSectionId } = course;
            if (!studentAttendance || !studentAttendance.length || !courseSectionId) return null;

            const dayNames = ['Sun', ...days];
            const attendanceDay = moment(selectedDate).startOf('week').add('days', dayNames.indexOf(day)).format('YYYY-MM-DD');
            const attendance = studentAttendance.find((a) => a.courseSectionId == courseSectionId
                && a.attendanceDate == attendanceDay && !a.deleted);
            return attendance || null;
        },
        showAttendance(meeting) {
            const { hovering } = this;
            const { course, day } = meeting;
            const key = `${course.courseSectionId}_${day}`;
            if (hovering == 'all') return;
            if (hovering.includes(key)) {
                this.hovering = hovering.filter((att) => att != key);
                return;
            }
            this.hovering.push(key);
        },
        showAllAttendance() {
            if (this.hovering == 'all') {
                this.hovering = [];
                return;
            }
            this.hovering = 'all';
        },
        dateForWeekday(day) {
            const { selectedDate } = this;
            if (day === 'Sun') return moment(selectedDate).startOf('week').format('MM/DD');
            if (day === 'Mon') return moment(selectedDate).startOf('week').add('days', 1).format('MM/DD');
            if (day === 'Tue') return moment(selectedDate).startOf('week').add('days', 2).format('MM/DD');
            if (day === 'Wed') return moment(selectedDate).startOf('week').add('days', 3).format('MM/DD');
            if (day === 'Thu') return moment(selectedDate).startOf('week').add('days', 4).format('MM/DD');
            if (day === 'Fri') return moment(selectedDate).startOf('week').add('days', 5).format('MM/DD');
            if (day === 'Sat') return moment(selectedDate).startOf('week').add('days', 6).format('MM/DD');
        },
    },
});
</script>

<style scoped src='../css/colors.scss' lang="scss" />
<style scoped>
    .table td, .table th {
        vertical-align: center;
        text-align: center;
    }
    .course-attendance {
        display: none !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .course-attendance span {
        font-size: 1.8rem;
        line-height: 0rem;
    }
    .course-info {
        display: block !important;
    }
    .hovered div.course-info {
        visibility: hidden !important;
    }
    .hovered div.course-info span:hover {
        opacity: 0 !important;
    }
    .hovered div.course-attendance{
        display: block !important;
    }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-portlet kt-portlet--head-noborder kt-portlet--height-fluid",
    },
    [
      _c("div", { staticClass: "kt-portlet__head" }, [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          _c(
            "h3",
            { staticClass: "kt-portlet__head-title" },
            [
              _vm._v(" " + _vm._s(_vm.markingPeriod.markingPeriodName) + " "),
              _c(
                "router-link",
                {
                  class: `kt-font-lg kt-font ${_vm.color}`,
                  attrs: {
                    to: {
                      name: "StudentCourseOverview",
                      params: {
                        extCourseSectionId: _vm.course.extCourseSectionId,
                        studentEnrollmentId: _vm.studentEnrollmentId,
                      },
                    },
                  },
                },
                [
                  _vm.reportCardGrade
                    ? _c("small", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.reportCardGrade.scaled.displayName) +
                            " "
                        ),
                      ])
                    : _vm.progressAverage
                    ? _c("small", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.progressAverage.scaled.displayName) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
          _vm._v(" " + _vm._s(_vm.teacherName) + " "),
        ]),
      ]),
      _vm.reportCardGrade || _vm.progressAverage
        ? _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "kt-widget20" }, [
                  _c(
                    "div",
                    { staticClass: "kt-widget20__content kt-portlet__space-x" },
                    [
                      _vm.reportCardGrade
                        ? _c("div", { staticClass: "media" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mr-3 align-self-center kt-badge kt-badge--xl kt-font-xl",
                                class: [_vm.color, "report-card-badge"],
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.reportCardGrade.mark) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "media-body" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "kt-widget20__number",
                                    class: `kt-font-lg kt-font ${_vm.color}`,
                                    staticStyle: { height: "32px" },
                                    attrs: {
                                      to: {
                                        name: "StudentCourseOverview",
                                        params: {
                                          extCourseSectionId:
                                            _vm.course.extCourseSectionId,
                                          studentEnrollmentId:
                                            _vm.studentEnrollmentId,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticClass: "kt-widget20__desc" },
                                  [
                                    _vm._v(" Report Card Grade: "),
                                    _c(
                                      "span",
                                      { class: `ml-2 kt-font ${_vm.color}` },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.reportCardGrade.mark) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm.progressAverage
                        ? _c("div", { staticClass: "media" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mr-3 align-self-center kt-badge kt-badge--xl kt-font-xl",
                                class: [_vm.color, "progress-average-badge"],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.progressAverage.scaled.mark) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "media-body" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "kt-widget20__number",
                                    class: `kt-font-lg kt-font ${_vm.color}`,
                                    staticStyle: { height: "32px" },
                                    attrs: {
                                      to: {
                                        name: "StudentCourseOverview",
                                        params: {
                                          extCourseSectionId:
                                            _vm.course.extCourseSectionId,
                                          studentEnrollmentId:
                                            _vm.studentEnrollmentId,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticClass: "kt-widget20__desc" },
                                  [
                                    _vm._v(" Progress Average: "),
                                    _vm.showPercentages
                                      ? _c(
                                          "span",
                                          {
                                            class: `ml-2 kt-font ${_vm.color}`,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.decimalToPercent(
                                                    _vm.progressAverage.average
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm.showScaledScores
                                      ? _c(
                                          "span",
                                          {
                                            class: `ml-2 kt-font ${_vm.color}`,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.progressAverage.scaled
                                                    .mark
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm.comments.length > 0
              ? _c("div", { staticClass: "row px-4 pt-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "kt-pagination kt-pagination--sm kt-pagination--info",
                      },
                      [
                        _c("div", { staticClass: "kt-pagination__toolbar" }, [
                          _c("span", { staticClass: "pagination__desc" }, [
                            _vm._v(
                              " Comment " +
                                _vm._s(_vm.selectedCommentIndex + 1) +
                                " of " +
                                _vm._s(_vm.comments.length) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("ul", { staticClass: "kt-pagination__links" }, [
                          _vm.selectedCommentIndex > 0
                            ? _c(
                                "li",
                                { staticClass: "kt-pagination__link--next" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.previousComment.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-left kt-font-info",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            { staticClass: "kt-pagination__link--prev" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.nextComment.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-angle-right kt-font-info",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "kt-section mb-0 pt-2" }, [
                      _c("div", { staticClass: "kt-section__content" }, [
                        _vm._v(" " + _vm._s(_vm.comment) + " "),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "kt-widget20__chart" },
              [
                _c("StudentBreakdownLineChart", {
                  attrs: {
                    category: _vm.reportCardGrade || _vm.progressAverage,
                    assignments: _vm.assignments,
                    "clicked-assignment": _vm.openPanelForCourseWork,
                    height: 130,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "kt-widget4 kt-widget4--sticky",
                style: { background: _vm.gradient, paddingTop: "64px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-widget4__items kt-widget4__items--bottom kt-portlet__space-x kt-margin-b-20",
                  },
                  _vm._l(_vm.categories, function (cat) {
                    return _c(
                      "div",
                      {
                        key: `category_${cat.gradeTemplateCategoryId}`,
                        staticClass: "kt-widget4__item",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-widget4__img kt-widget4__img--logo",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "btn btn-icon progress-average-badge mr-1",
                                class: [cat.scaled.color, `btn-sm`],
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "course-icon kt-font-lg align-items-center d-flex",
                                  },
                                  [_vm._v(" " + _vm._s(cat.scaled.mark) + " ")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "kt-widget4__info" }, [
                          _c(
                            "a",
                            {
                              staticClass: "kt-widget4__title",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(cat.categoryName) + " ")]
                          ),
                          _c("span", { staticClass: "kt-widget4__sub" }, [
                            _vm._v(
                              " Missing: " +
                                _vm._s(cat.totals.missing) +
                                " of " +
                                _vm._s(cat.totals.all) +
                                " "
                            ),
                          ]),
                        ]),
                        !cat.prorated
                          ? _c("span", { staticClass: "kt-widget4__ext" }, [
                              _vm.showPercentages
                                ? _c(
                                    "span",
                                    {
                                      class: `kt-widget4__number kt-font ${cat.scaled.color}`,
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.decimalToPercent(
                                              cat.percentEarned
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm.showScaledScores
                                ? _c(
                                    "span",
                                    {
                                      class: `kt-widget4__number kt-font ${cat.scaled.color}`,
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(cat.scaled.mark) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("span", [
                              _c(
                                "span",
                                { class: `kt-widget4__number kt-font Grey` },
                                [_vm._v(" N/A ")]
                              ),
                            ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ])
        : _c("div", { staticClass: "kt-portlet__body" }, [
            _c(
              "h4",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "kt-widget20__number",
                    class: `kt-font-lg kt-font ${_vm.color}`,
                    staticStyle: { height: "32px" },
                    attrs: {
                      to: {
                        name: "StudentCourseOverview",
                        params: {
                          extCourseSectionId: _vm.course.extCourseSectionId,
                          studentEnrollmentId: _vm.studentEnrollmentId,
                        },
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
                ),
              ],
              1
            ),
            _vm._m(0),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-3 pb-5" }, [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(" This course does not have enough data to supply an average "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
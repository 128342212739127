var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__head kt-portlet__head--lg" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c(
          "span",
          { staticClass: "kt-portlet__head-icon pr-0" },
          [
            _c("SVGIcon", {
              staticClass: "mr-3",
              attrs: { "hex-color": "#0f88ef", name: "calendar" },
            }),
          ],
          1
        ),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            class: {
              "btn-clean btn-clean-primary": _vm.hovering != "all",
              "btn-label-primary": _vm.hovering == "all",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.showAllAttendance()
              },
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.hovering == "all" ? "Hide Attendance" : "View Attendance"
                ) +
                " "
            ),
          ]
        ),
        _c(
          "span",
          { staticClass: "ml-2" },
          [
            _c("b-form-datepicker", {
              attrs: {
                "no-key-nav": true,
                "button-only": "",
                right: "",
                "button-variant": "primary",
                autocomplete: "off",
                locale: "en-US",
              },
              on: { context: _vm.onContextEnd },
              model: {
                value: _vm.selectedDate,
                callback: function ($$v) {
                  _vm.selectedDate = $$v
                },
                expression: "selectedDate",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "kt-section__content pt-3" }, [
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", [_vm._v("Period")]),
                  _vm._l(_vm.days, function (day, idx) {
                    return _c("th", { key: `${day}_${idx}` }, [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(" " + _vm._s(day) + " "),
                        _c("div", [
                          _vm._v(" " + _vm._s(_vm.dateForWeekday(day)) + " "),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.tableData, function (row, rowIndex) {
                return _c(
                  "tr",
                  { key: `row_${row.period}_${rowIndex}` },
                  [
                    _c("td", [
                      _c("div", { staticClass: "text-center mt-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn btn-md btn-icon",
                            class: [
                              row.meetings.find((m) => m.course)
                                ? row.color
                                : "",
                            ],
                            staticStyle: { cursor: "default !important" },
                          },
                          [
                            row.meetings.find((m) => m.course)
                              ? _c("span", { style: { color: row.hexColor } }, [
                                  _vm._v(" " + _vm._s(row.period) + " "),
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(row.period) + " "),
                                ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._l(row.meetings, function (meeting, meetingIdx) {
                      return _c(
                        "td",
                        {
                          key: `row_${row.period}_${rowIndex}_${meeting.day}_${meetingIdx}`,
                        },
                        [
                          meeting.course
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-clean position-relative",
                                  class: [
                                    "btn-clean-" + row.color,
                                    {
                                      hovered: _vm.hovering.includes(
                                        `${meeting.course.courseSectionId}_${meeting.day}`
                                      ),
                                    },
                                    { hovered: _vm.hovering == "all" },
                                  ],
                                  attrs: {
                                    to: {
                                      name: "StudentCourseOverview",
                                      params: {
                                        studentEnrollmentId:
                                          _vm.student.studentEnrollmentId,
                                        extCourseSectionId:
                                          meeting.course.extCourseSectionId,
                                      },
                                    },
                                  },
                                  nativeOn: {
                                    mouseover: function ($event) {
                                      return _vm.showAttendance(meeting)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "course-info" }, [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            meeting.course.extCourseSectionId
                                          ) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " + _vm._s(meeting.course.name) + " "
                                      ),
                                      _c("br"),
                                      _vm._v(" " + _vm._s(meeting.room) + " "),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "course-attendance" },
                                    [
                                      meeting.attendance
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                "btn btn-icon btn-sm btn-upper btn-bold att-text",
                                                meeting.attendance
                                                  .attendanceColor,
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    `${
                                                      meeting.attendance
                                                        .attendanceState || ""
                                                    }`
                                                      .toUpperCase()
                                                      .substring(0, 1)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : !meeting.attendance
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "btn btn-icon btn-sm btn-upper btn-bold att-text Grey",
                                            },
                                            [_vm._v(" - ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-muted" }, [
                                _vm._v(" - "),
                              ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "kt-portlet__head-title" }, [
      _c("span", [_vm._v(" Student Grid Schedule ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
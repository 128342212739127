<template>
<tr>
    <td>
        <!-- Period / Room -->
        <div class="media pt-1">
            <div
                v-if="course.nextMeeting && course.nextMeeting.day"
                style="width: 80px"
                class="mr-3"
            >
                <span class="kt-font-lg">Pd {{ course.nextMeeting.period }}</span>
                <br>
                <span class="text-muted">Rm {{ course.nextMeeting.room }}</span>
            </div>
            <div class="media-body">
                <router-link
                    :to="{name: 'StudentCourseOverview', params: { extCourseSectionId: course.extCourseSectionId, studentEnrollmentId}}"
                    class="course-title kt-font-bold kt-font-lg"
                >
                    {{ course.name }}
                </router-link>
                <div
                    v-if="course.teachers.length == 0"
                    class="text-muted"
                >
                    No Teachers
                </div>
                <div
                    v-else
                    class="text-muted"
                >
                    <router-link
                        v-for="teacher in course.teachers"
                        :key="`${teacher.schoolStaffId}_teacher`"
                        :to="{name: 'TeacherCourseInfo', params: { extCourseSectionId: course.extCourseSectionId, schoolEmail: teacher.schoolEmail }}"
                        class="mr-3 kt-link kt-font-dark"
                    >
                        {{ teacher.lastName }}
                    </router-link>
                </div>
            </div>
        </div>
    </td>
    <td>
        <!-- Marking Period Grades -->
        <div :class="`hstack row no-wrap hstack-marks-${course.studentMarkingPeriodAverages.length}mp`">
            <StudentMarkingPeriodMark
                v-for="avg in course.studentMarkingPeriodAverages"
                :key="`studentMarkingPeriodAverage_${avg.markingPeriod.schoolTermMarkingPeriodId}`"
                class="marking-period-grade mr-2 py-2"
                :class="[
                    avg.markingPeriod.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId ? 'active-marking-period' : '',
                ]"
                :course="course"
                :student-marking-period-average="avg"
            />
        </div>
    </td>
    <td>
        <!-- Attendance -->
        <div class="kt-widget29 mt-0 pt-1 ml-3">
            <div class="kt-widget29__content p-0">
                <div class="kt-widget29__item">
                    <div class="kt-widget29__info text-center">
                        <span
                            :class="[
                                'kt-widget29__stats',
                                course.studentCourseAttendance.percent.present >= 95 ? 'kt-font-primary' : '',
                                course.studentCourseAttendance.percent.present >= 90 ? 'kt-font-success' : 'kt-font-danger',
                            ]"
                        >
                            {{ course.studentCourseAttendance.percent.present }}%
                        </span>
                        <span class="kt-widget29__subtitle">YTD</span>
                    </div>
                </div>
            </div>
        </div>
    </td>
    <td>
        <div class="kt-widget29 mt-0 pt-1">
            <div class="kt-widget29__content p-0">
                <div class="kt-widget29__item">
                    <div class="kt-widget29__info text-center">
                        <span class="kt-widget29__stats kt-font-danger">
                            {{ course.studentCourseAttendance.total.absent }}
                        </span>
                        <span class="kt-widget29__subtitle">ABS</span>
                    </div>
                </div>
            </div>
        </div>
    </td>
    <td>
        <div class="hstack hstack-attendance row no-wrap ml-3">
            <span
                v-for="(att, idx) in thisWeeksAttendance"
                :key="`attendance_${idx}_${att.attendanceDate}`"
                class="mr-2 pt-2"
            >
                <a
                    v-b-tooltip.hover
                    href="#"
                    :title="att.title"
                    :class="`kt-badge kt-badge--lg kt-badge--bold kt-font-lg kt-badge--unified-${att.className}`"
                    @click.stop.prevent=""
                >
                    {{ att.record ? att.record.attendanceState.substring(0,1) : '-' }}
                </a>

            </span>
        </div>
    </td>
</tr>
</template>

<script lang="ts">

import moment from 'moment';
import StudentMarkingPeriodMark from './StudentMarkingPeriodMark.vue';
import courseMixins from '../store/mixins/courseMixins';

export default {
    name: 'StudentCourseListRow',
    components: {
        StudentMarkingPeriodMark,
    },
    mixins: [courseMixins],
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        const data = {
            today: moment().format('dddd'),
        };
        return data;
    },
    computed: {
        teachers() {
            const { course } = this;
            if (!course.teachers) return '';
            return course.teachers
                .map((t) => `${t.lastName} ${t.firstName.substring(0, 1)}`)
                .join(', ');
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        startOfWeek() {
            const [monday] = this.thisWeeksAttendance;
            return monday.attendanceDate.substring(5);
        },
        thisWeeksAttendance() {
            const { course } = this;
            if (!course) return null;
            const days = [...Array(5).keys()].map((idx) => moment().startOf('week').add(idx + 1, 'days').format('YYYY-MM-DD'));
            return days.map((day) => {
                const record = course.studentCourseAttendance.records.find((a) => a.attendanceDate == day && !a.deleted) || null;
                const formattedDate = moment(day).format('ddd, MMM Do');
                const output = {
                    attendanceDate: day,
                    record,
                    formattedDate,
                    title: `No Attendance Recorded on ${formattedDate}`,
                    className: 'brand',
                };
                if (!record) return output;

                if (record.attendanceState == 'Present') output.className = 'success';
                if (record.attendanceState == 'Absent') output.className = 'danger';
                if (record.attendanceState == 'Late') output.className = 'warning';
                if (record.attendanceState == 'Excused') output.className = 'info';

                output.title = `${record.attendanceState} on ${formattedDate} ${record.attendanceComment || ''}`;
                return output;
            });
        },
    },
};
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>

tr td {
    vertical-align: middle !important;
}
.hstack-attendance {
    min-width: 240px;
}
.hstack-marks-1mp {
    width: 46px;
}
.hstack-marks-2mp {
    width: calc(2 * 46px);
}
.hstack-marks-3mp {
    width: calc(3 * 46px);
}
.hstack-marks-4mp {
    width: calc(4 * 46px);
}
.hstack-marks-5mp {
    width: calc(5 * 46px);
}
.hstack-marks-6mp {
    width: calc(6 * 46px);
}

div.media {
    min-width: 280px;
}
.marking-period-grade {
    min-width: 39px;
    max-width: 39px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
/* .active-marking-period {
    border-bottom: 1px solid #0f88ef;
} */

a.course-title:hover {
    color: #339bf3 !important;
}
a.course-title {
    min-width: 100px;
    max-width: 220px;
    padding-right: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.kt-widget29__content {
    background: transparent !important;
}

</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet" }, [
    _c(
      "div",
      { staticClass: "kt-portlet__body pt-0" },
      [
        !_vm.isPortfolioReady
          ? _c("PortletLoader")
          : _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-hover" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", {}, [_vm._v(" Courses ")]),
                    _c("th", { staticClass: "px-0" }, [_vm._v(" Grades ")]),
                    _c(
                      "th",
                      {
                        staticClass: "text-center px-0",
                        attrs: { colspan: "3" },
                      },
                      [_vm._v(" Attendance ")]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.studentCourses, function (course, idx) {
                    return _c("StudentCourseListRow", {
                      key: `courseList_${idx}_${course["extCourseSectionId"]}`,
                      attrs: { course: course },
                    })
                  }),
                  1
                ),
              ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="kt-portlet">
    <div class="kt-portlet__body pt-0">
        <PortletLoader v-if="!isPortfolioReady" />
        <div
            v-else
            class="table-responsive"
        >
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="">
                            Courses
                        </th>
                        <th class="px-0">
                            Grades
                        </th>
                        <th
                            colspan="3"
                            class="text-center px-0"
                        >
                            Attendance
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <StudentCourseListRow
                        v-for="(course, idx) in studentCourses"
                        :key="`courseList_${idx}_${course['extCourseSectionId']}`"
                        :course="course"
                    />
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>

import StudentCourseListRow from './StudentCourseListRow.vue';
import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';
import PortletLoader from './PortletLoader.vue';

export default {
    name: 'StudentCourseListPortlet',
    components: {
        StudentCourseListRow,
        PortletLoader,
    },
    props: {
        student: {
            type: Object,
            required: true,
        },
        studentCourses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean((this.$store.state.database.cache).find((c) => c.key == cacheKey && c.status == 'cached'));
        },
    },
};
</script>

<style lang="scss" scoped>
.table th {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.1rem !important;
    font-weight: 400 !important;
}
</style>
